export const googleId = process.env.REACT_APP_GOOGLE_ID;
export const facebookId = process.env.REACT_APP_FACEBOOK_ID;
export const stripeKey = process.env.REACT_APP_STRIPE_KEY;

export const logo = process.env.PUBLIC_URL + "/images/logo_muted.svg";
export const logoColor = process.env.PUBLIC_URL + "/images/logo_color.svg";
export const loginSVG = process.env.PUBLIC_URL + "/images/login.svg";
export const base_url = process.env.REACT_APP_BASE_URL;
export const stripeLogo =
  process.env.PUBLIC_URL + "/images/powered_by_stripe.svg";
export const mastercard = process.env.PUBLIC_URL + "/images/mastercard.svg";
export const visa = process.env.PUBLIC_URL + "/images/visa.svg";

// get the true inner viewport height and multiply it by 0.01 to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);
