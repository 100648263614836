import React, { useContext, useState, useReducer } from "react";
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from "react-stripe-elements";
import { Modal, notification, Icon, Button } from "antd";

import { getParsedToken } from "../../../auth";
import { logo } from "../../../globals";
import "./PaymentForm.css";
import { BookingsContext } from "../../../Context/BookingsContext";
import classes from "./PaymentForm.module.scss";
import PaymentInfo from "./PaymentInfo";
import CardVendors from "./CardVendors";

const cn = require("classnames");

const reducer = (state, action) => {
  if (action.type == "cardNumber") {
    const { cardExpiry, cardCvc } = state;
    return {
      cardNumber: action.value,
      cardExpiry,
      cardCvc,
      isFormValid: action.value && cardExpiry && cardCvc,
    };
  }
  if (action.type == "cardExpiry") {
    const { cardNumber, cardCvc } = state;
    return {
      cardNumber,
      cardExpiry: action.value,
      cardCvc,
      isFormValid: cardNumber && action.value && cardCvc,
    };
  }
  if (action.type == "cardCvc") {
    const { cardNumber, cardExpiry } = state;
    return {
      cardNumber,
      cardExpiry,
      cardCvc: action.value,
      isFormValid: cardNumber && cardExpiry && action.value,
    };
  }
};

const PaymentForm = (props) => {
  const {
    cleanupOnPaymentFail,
    cleanupOnPaymentSucceeded,
    drawerState,
    getEvents,
    handleBookingsCleanup,
    paymentDetails: { clientSecret },
    sendBookingsToServer,
    setDrawerState,
    setShowPaymentForm,
    setUserBookings,
    totalPrice,
  } = useContext(BookingsContext);

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [{ isFormValid }, dispatch] = useReducer(reducer, {
    cardNumber: false,
    expiryDate: false,
    cvc: false,
    isFormValid: false,
  });
  const { username } = getParsedToken();

  const handleInputChange = ({ complete, elementType }) => {
    dispatch({ type: elementType, value: complete });
  };

  const closeDrawerOnMobile = () => {
    const { mobile } = drawerState;
    if (mobile) {
      setDrawerState((prev) => ({ ...prev, show: false }));
    }
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setLoading(true);
    try {
      await sendBookingsToServer();
      if (props.stripe) {
        props.stripe.createToken().then(({ token: { id } }) => {
          props.stripe
            .confirmCardPayment(clientSecret, {
              payment_method: {
                card: { token: id },
                billing_details: {
                  name: username,
                },
              },
            })
            .then(async (result) => {
              if (result.error) {
                await cleanupOnPaymentFail();
                const { message } = result.error;
                setLoading(false);
                setError(message);
              } else {
                cleanupOnPaymentSucceeded();
                setShowPaymentForm(false);
                closeDrawerOnMobile();
                notification.success({
                  message: "Rezervarea a fost facută cu success",
                  description:
                    "Veți primi confirmarea de plată și detaliile rezervării pe email. Dacă nu ați primit și al doilea email cu detaliile rezervării, vă rugăm să verificați și in spam.",
                  duration: 0,
                });
              }
            });
        });
      } else {
      }
    } catch (err) {
      await getEvents();
      setUserBookings([]);
      notification.error({
        message: "Rezervare eșuată!",
        description:
          "A aparut o eroare sau altcineva tocmai a rezervat intervalul ales. Plata nu a fost efectuată. Vă rugăm alegeți un alt interval, sau contactați-ne la 0770257899",
        duration: null,
      });
      handleClose();
    }
  };

  const handleClose = () => {
    handleBookingsCleanup();
    setShowPaymentForm(false);
  };

  const { email } = getParsedToken();

  return (
    <Modal
      visible={true}
      footer={false}
      onCancel={handleClose}
      width={700}
      bodyStyle={{ padding: "0" }}
      className={classes.modal}
    >
      <div className={classes.modalContainer}>
        <div className={classes.contentContainer}>
          <PaymentInfo />
        </div>
        <div className={classes.contentContainer}>
          <form onSubmit={handleSubmit}>
            <div className={classes.cardNumberContainer}>
              <CardVendors></CardVendors>
              <label className="label">
                Numărul cardului
                <CardNumberElement
                  className={classes.input}
                  onChange={handleInputChange}
                />
              </label>
            </div>
            <div className={classes.inputContainer}>
              <label className="label">
                Data expirării
                <CardExpiryElement onChange={handleInputChange} />
              </label>
            </div>
            <div className={classes.inputContainer}>
              <label className="label">
                CVC/CVV
                <CardCVCElement onChange={handleInputChange} />
              </label>
            </div>
            {error && (
              <p
                style={{
                  textAlign: "center",
                  color: "red",
                  fontWeight: "500",
                }}
              >
                {error}
              </p>
            )}
            <Button
              className={classes.payButton}
              onClick={handleSubmit}
              loading={loading}
              disabled={!isFormValid}
            >
              Plătiți {totalPrice} de lei
            </Button>
            <p className={classes.smallText}>
              Veți primi rezervarea și o confirmare de plată pe
            </p>
            <p className={classes.emailText}>{email}</p>
          </form>
          <div className={classes.logoContainer}>
            <img className={classes.logo} src={logo} alt="logo" />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default injectStripe(PaymentForm);
