import React from "react";
import BookingsManager from "../../CustomerPage/BookingsManager/BookingsManager";
import { Row, Col } from "antd";
import CourtScheduler from "../../CustomerPage/CourtScheduler/CourtScheduler";
import CourtButtons from "../../CustomerPage/CourtButtons/CourtButtons";
import PriceDrawer from "../../CustomerPage/PriceDrawer/PriceDrawer";
import PageHeader from "../../CustomerPage/PageHeader/PageHeader";
import classes from "../../CustomerPage/CustomerPage.module.scss";

const NewBookings = () => {
  return (
    <div>
      <PageHeader></PageHeader>
      <BookingsManager>
        <Row>
          <Col
            // span={24}
            style={{
              margin: "0 24px"
            }}
          >
            <CourtButtons></CourtButtons>
          </Col>
        </Row>
        <Row className={classes.schedulerContainer}>
          <CourtScheduler></CourtScheduler>
          <PriceDrawer isAdmin={true}></PriceDrawer>
        </Row>
      </BookingsManager>
    </div>
  );
};

export default NewBookings;
