import React from "react";
import classes from "./PageHeader.module.scss";
import { logoColor } from "../../../globals";

const PageHeader = () => {
  return (
    <div className={classes.header}>
      <a href="/">
        <img src={logoColor} alt="logo" className={classes.logo} />
      </a>
      <div className={classes.infoContainer}>
        <h1>CLUB iTENIS IAȘI</h1>
      </div>
    </div>
  );
};

export default PageHeader;
