import React from "react";

import { stripeLogo } from "../../../globals";
import classes from "./PaymentForm.module.scss";

const PaymentInfo = () => {
  return (
    <>
      <div className={classes.infoContainer}>
        <p className={classes.title}>Plătește rapid și în siguranță  <img src={stripeLogo} alt="stripe-logo" className={classes.img} /></p>
        <p className={classes.text}>
          Toate tranzacțiile online sunt efectuate extern prin platforma Stripe. iTENIS IAȘI nu poate vedea și nu stochează sub nicio formă datele
          cardului dumneavoastră.
        </p>
        <p className={classes.text}>
          Tot ce trebuie să faceți este să ne aratați email-ul de confirmare la intrarea pe teren. Dacă preferați să plătiți cash, contactați-ne la 0771 257 899.
        </p>
      </div>
      <div className={classes.infoContainer}>
        <p className={classes.title}>Ce fac dacă nu mai pot ajunge?</p>
        <p className={classes.text}>
          Ne puteți contacta cu cel putin 24 de ore înaintea rezervării inițiale
          pentru schimbarea gratuită pe un alt interval orar disponibil.
        </p>
        <p className={classes.text}>
          În cazul condițiilor de vreme care nu permit jocul, vă oferim, la cerere,
          schimbarea rezervării sau{" "}
          <span className={classes.bold}>returul integral</span> al plății.
        </p>
      </div>
    </>
  );
};

export default PaymentInfo;
