import React from "react";
import Header from "./Header";
import LegalButtons from "./LegalButtons";
import PrivacyContent from "./PrivacyContent";
import Contact from "../LandingPage/Contact/Contact";
import classes from "./Legal.module.scss"


const Privacy = () => {
  return (
    <>
      <Header />
      <div className={classes.container}>
        <LegalButtons></LegalButtons>
        <PrivacyContent></PrivacyContent>
      </div>
      <Contact></Contact>
    </>
  );
};

export default Privacy;
