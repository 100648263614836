import React from "react";
import { Row, Col, Icon } from "antd";
import classes from "./Contact.module.scss";
import { logo } from "../../../globals";

const Contact = () => {
  return (
    <Row className={classes.container}>
      <Col xs={{ span: 18, offset: 1 }} md={{ span: 6, offset: 2 }}>
        <h2>CONTACT</h2>
        <div className={classes.infoContainer}>
          <Icon type="pushpin" theme="outlined" twoToneColor="#cb5223" />
          <h3>Stradela Sofia Nădejde 2, Iași 700620</h3>
        </div>
        <div className={classes.infoContainer}>
          <Icon type="phone" theme="outlined" twoToneColor="#cb5223" />
          <h3>(0771) 257 899</h3>
        </div>
        <div className={classes.infoContainer}>
          <Icon type="mail" theme="outlined" twoToneColor="#cb5223" />
          <h3>contact@tenisiasi.ro</h3>
        </div>
        <div className={classes.infoContainer}>
          <Icon type="schedule" theme="outlined" twoToneColor="#cb5223" />
          <h3>Luni – Duminică 8 – 23</h3>
        </div>
      </Col>
      <Col xs={{ span: 18, offset: 1 }} md={{ span: 8, offset: 2 }}>
        <h2 className={classes.tocTitleText}>TERMENI ȘI INFORMAȚII</h2>
        <div className={classes.infoContainer}>
          <Icon type="read" theme="outlined" twoToneColor="#cb5223" />
          <h3>
            <a href="/terms-and-conditions" target="_blank">
              Termeni și condiții de utilizare
            </a>
          </h3>
        </div>
        <div className={classes.infoContainer}>
          <Icon type="audit" theme="outlined" twoToneColor="#cb5223" />
          <h3>
            <a href="/privacy-policy" target="_blank">
              Politica de confidențialitate
            </a>
          </h3>
        </div>
      </Col>
      <Col style={{ position: "static" }}>
        <img src={logo} alt="logo" className={classes.logo} />
      </Col>
    </Row>
  );
};

export default Contact;
