import moment from "moment";

export const storeToken = (accessToken) => {
  sessionStorage.setItem("access_token", accessToken);
};

export const getAccessToken = () => {
  const token = sessionStorage.getItem("access_token");
  return token;
};

export const getParsedToken = () => {
  const token = getAccessToken();
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const hasTokenExpired = () => {
  const { exp = 0 } = getParsedToken() || {};
  const expiryDate = new Date(0).setUTCSeconds(exp);

  return expiryDate < Date.now();
};

export const adminAccess = () => {
  const { role = "" } = getParsedToken() || {};
  return role === "Admin";
};

export const logOut = () => {
  window.sessionStorage.removeItem("access_token");
};
