import React from "react";
import { Modal, Form, Input, Checkbox, notification } from "antd";
import axios from "../../../../axiosAuthorized.config";

const modalInitialState = {
  show: false,
  court: {},
  price: {},
  newCourt: true,
};

const CourtModal = ({ form, modalState, setModalState, setCourts }) => {
  const { getFieldDecorator, validateFields, resetFields } = form;
  const { show, court, price, newCourt } = modalState;
  const { name, type, active = false, id } = court;
  const { day_value, night_value } = price;

  const handleEdit = async (values) => {
    const { name, type, day_value, night_value, active } = values;
    const requestBody = {
      court: { active, type, name, id },
      price: {
        day_value: parseInt(day_value),
        night_value: parseInt(night_value),
      },
    };
    try {
      const { data } = await axios.put("admin/courts", requestBody);
      setCourts((prev) =>
        prev.map((el) => (el.court.id === data.court.id ? data : el))
      );
      setModalState(modalInitialState);
      resetFields();
      notification.success({ message: "Terenul a fost editat cu succes" });
    } catch (err) {
      notification.error({
        message: "Am întâmpinat o problemă",
        description: "Vă rugăm încercați mai târziu",
      });
    }
  };

  const handleCreate = async (values) => {
    const { name, type, day_value, night_value, active } = values;
    const requestBody = {
      court: { active, type, name },
      price: {
        day_value: parseInt(day_value),
        night_value: parseInt(night_value),
      },
    };

    try {
      const { data } = await axios.post("admin/courts", requestBody);
      setCourts((prev) => [...prev, data]);
      setModalState(modalInitialState);
      notification.success({ message: "Terenul a fost creat cu succes" });
    } catch (err) {
      notification.error({
        message: "Am întâmpinat o problemă",
        description: "Vă rugăm încercați mai târziu",
      });
    }
  };

  const handleSubmit = () => {
    validateFields(async (err, values) => {
      if (!err) {
        newCourt ? handleCreate(values) : handleEdit(values);
      }
    });
  };

  return (
    <Modal
      visible={show}
      okType="default"
      onOk={handleSubmit}
      onCancel={() => {
        setModalState(modalInitialState);
      }}
    >
      <Form>
        <Form.Item label="Nume">
          {getFieldDecorator("name", {
            initialValue: name,
            rules: [{ required: true, message: "Introduceți un nume" }],
          })(<Input placeholder="Teren 1"></Input>)}
        </Form.Item>
        <Form.Item label="Tip">
          {getFieldDecorator("type", {
            initialValue: type,
            rules: [{ required: true, message: "Introduceți o descriere" }],
          })(<Input placeholder="Aer liber"></Input>)}
        </Form.Item>
        <Form.Item label="Preț zi">
          {getFieldDecorator("day_value", {
            initialValue: day_value,
            rules: [{ required: true, message: "Introduceți un preț" }],
          })(<Input placeholder="25" type="number"></Input>)}
        </Form.Item>
        <Form.Item label="Preț nocturnă">
          {getFieldDecorator("night_value", {
            initialValue: night_value,
            rules: [{ required: true, message: "Introduceți un preț" }],
          })(<Input placeholder="30" type="number"></Input>)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("active", {
            initialValue: active,
            valuePropName: "checked",
          })(<Checkbox>Activ</Checkbox>)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(CourtModal);
