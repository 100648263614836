import React, { Suspense } from "react";
import HomePage from "./HomePage/HomePage";
// import AboutUs from "./AboutUs/AboutUs";
// import Gallery from "./Gallery/Gallery";
// import Contact from "./Contact/Contact";
// import Courses from "./Courses/Courses";
import { BackTop } from "antd";

const AboutUs = React.lazy(() => import("./AboutUs/AboutUs"));
// const Courses = React.lazy(() => import("./Courses/Courses"));
const Gallery = React.lazy(() => import("./Gallery/Gallery"));
const Contact = React.lazy(() => import("./Contact/Contact"));

const LandingPage = () => {
  return (
    <div>
      <HomePage></HomePage>
      <Suspense fallback={<div>...loading</div>}>
        <AboutUs></AboutUs>
        {/* <Courses></Courses> */}
        <Gallery></Gallery>
        <Contact></Contact>
      </Suspense>
      <BackTop></BackTop>
    </div>
  );
};

export default LandingPage;
