/*eslint no-restricted-globals: ["error", "event"]*/

import React from "react";
import { Menu, Icon } from "antd";
import classes from "./Nav.module.scss";

const Nav = () => {
  const height = window.innerHeight;

  return (
    <Menu className={classes.nav} selectedKeys={["0"]} mode="horizontal">
      <Menu.Item key="about-us" onClick={() => scroll(0, 1 * height)}>
        <h2>DESPRE NOI</h2>
      </Menu.Item>
      <Menu.Item key="courses" onClick={() => scroll(0, 2 * height)}>
        <h2>CURSURI</h2>
      </Menu.Item>
      <Menu.Item key="galery" onClick={() => scroll(0, 3 * height)}>
        <h2>GALERIE</h2>
      </Menu.Item>

      <Menu.Item key="contact" onClick={() => scroll(0, 4 * height)}>
        <h2>CONTACT</h2>
      </Menu.Item>
    </Menu>
  );
};

export default Nav;
