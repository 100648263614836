import React, { useState, useEffect, useContext } from "react";
import { Select, Button } from "antd";
import axios from "../../../axios.config";
import { BookingsContext } from "../../../Context/BookingsContext";
import classes from "./CourtButtons.module.scss";

var classNames = require("classnames");

const { Option } = Select;

const CourtDropdown = () => {
  const [courts, setCourts] = useState([]);
  const { setCourt, court } = useContext(BookingsContext);

  useEffect(() => {
    const getCourts = async () => {
      const { data: { courts = [] } = {} } = await axios.get("courts");
      const sortedCourts = courts.sort((a, b) => a.name.localeCompare(b.name));
      setCourts(sortedCourts);
      const [firstCourt] = sortedCourts;
      setCourt({ courtId: firstCourt.id, name: firstCourt.name });
    };

    getCourts();
  }, []);

  const renderOption = ({ id, name }) => (
    <Option key={id} value={id}>
      {name}
    </Option>
  );

  return (
    <>
      <Button.Group className={classes.group}>
        {courts.map((el) => (
          <Button
            className={classNames("btn", {
              [classes.active]: el.id === court.courtId,
            })}
            key={el.id}
            disabled={!el.active}
            onClick={() => {
              setCourt({ courtId: el.id, name: el.name });
            }}
          >
            <p>{el.name}</p>
            <span>({el.type})</span>
          </Button>
        ))}
      </Button.Group>
    </>
  );
};

export default CourtDropdown;
