import React from "react";
import classes from "./TermsContent.module.scss";

const TermsContent = () => {
  return (
    <div className={classes.content}>
      <p className={classes.title}>Termeni și Condiții de utilizare</p>
      <p className={classes.updateText}>
        Ultima actualizare a avut loc în data de 5 August 2020
      </p>
      
      <p className={classes.text}>
        Vă rugăm să vizitați această pagină periodic pentru a lua la cunoștiință
        eventuale schimbări ai Termenilor și Condițiilor de folosire. Folosirea
        platformei itenisiasi.ro și rezervarea telefonică sunt condiționate de acceptarea acestui document și a
        Politicii de Confidențialitate.
      </p>

      <p className={classes.text}>
        Accesul pe teren este oferit persoanelor de orice vârstă. Folosirea
        platformei de rezervări este permisă doar persoanelor de peste 18 ani.
        Dacă sunteți minor și doriți să faceți o rezervare, vă rugăm să vă
        adresați unui părinte sau tutore.
      </p>

      <p className={classes.text}>
        Conținutul și serviciile furnizate de către platforma itenisiași.ro sunt
        pentru doar pentru uz personal în scopul rezervării unui teren sportiv.
        Nu permitem utilizarea informațiilor accesibile de pe site în niciun alt
        scop.
      </p>

      <p className={classes.text}>
        Rezervarea dumneavoastră vă oferă accesul pe teren pe durata rezervării
        cu scopul activității sportive.
      </p>

      <p className={classes.heading}>Noi garantăm:</p>
      <p className={classes.text}>
        - Disponibilitatea terenului la începerea intervalului orar rezervat.
      </p>
      <p className={classes.text}>
        - Funcționalitatea terenului și posibilitatea desfășurării jocului de tenis pe acesta.
      </p>

      <p className={classes.heading}>Pe dumneavoastră, vă rugăm:</p>
      <p className={classes.text}>
        - Să fiți prezent în intervalul rezervat. Ne rezervăm dreptul de a
        refuza viitoare rezervări clienților care nu se prezintă la rezervare în
        repetate rânduri.
      </p>
      <p className={classes.text}>
        - Să respectați terenul ales/stabilit la momentul rezervării.
      </p>
      <p className={classes.text}>
        - Să eliberați terenul până la sfârșitul intervalului rezervat, mai ales
        dacă următorii clienți au sosit la club.
      </p>
      <p className={classes.text}>
        - Să nu consumați băuturi alcoolice sau să fumați pe teren.
      </p>
      <p className={classes.text}>
        - Să ne aduceți la cunoștiință orice problemă apărută care nu
        permite desfășurarea jocului pentru a o putea soluționa sau, după caz, a vă
        putea oferi un alt teren, o altă rezervare, sau restituirea tarfiului.
      </p>

      <p className={classes.heading}>Schimbarea rezervării</p>
      <p className={classes.text}>
        Ne puteți contacta cu cel puțin 24 de ore înaintea rezervării inițiale
        pentru schimbarea gratuită pe un alt interval orar disponibil.
      </p>

      <p className={classes.heading}>Condiții meteo nefavorabile</p>
      <p className={classes.text}>
        În cazul condițiilor de vreme care nu permit jocul, vă oferim, la
        cerere, schimbarea rezervării sau returul integral al plății.
      </p>
    </div>
  );
};

export default TermsContent;
