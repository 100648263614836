import React from "react";
import { Button } from "antd";
import classes from "./LegalButtons.module.scss";
import { navigate } from "@reach/router";

const LegalButtons = () => {
  return (
    <div className={classes.container}>
      <Button className={classes.button} onClick={() => navigate("/terms-and-conditions")}>
        <h3 className={classes.buttonText}>Termeni și Condiții de utilizare</h3>
      </Button>
      <Button className={classes.button} onClick={() => navigate("/privacy-policy")}>
        <h3 className={classes.buttonText}>Politica de Confidențialitate</h3>      
      </Button>
    </div>
  );
};

export default LegalButtons;
