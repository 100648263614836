import React from "react";
import { UserContext } from "../../../Context/UserContext";
import { useState } from "react";
import { adminAccess } from "../../../auth";

const hasToken = () => !!sessionStorage.getItem("access_token");

const UserManager = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(hasToken());
  const isAdmin = adminAccess();

  return (
    <UserContext.Provider value={{ isLoggedIn, setIsLoggedIn, isAdmin }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserManager;
