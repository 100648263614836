import React, { useEffect, useState, useContext } from "react";
import { Drawer, Row, Button, notification, Input } from "antd";
import axios from "../../../axios.config";
import Fallback from "../../Common/Fallback/Fallback";
import { BookingsContext } from "../../../Context/BookingsContext";
import { UserContext } from "../../../Context/UserContext";
import classes from "./PriceDrawer.module.scss";
import { logo } from "../../../globals";
import moment from "moment";
import "moment/locale/ro";
import { useRef } from "react";
import { hasTokenExpired } from "../../../auth";

const logoPath = "/../../../../public/images/logo_muted.png";

const priceReducer = (acc, curr) => {
  return {
    dayTime: {
      count: curr.day_time.count + acc.dayTime.count,
      price: curr.day_time.price,
    },
    nightTime: {
      count: curr.night_time.count + acc.nightTime.count,
      price: curr.night_time.price,
    },
    total: curr.total + acc.total,
  };
};

const PriceDrawer = ({ isAdmin = false }) => {
  const [price, setPrice] = useState({});
  const notesRef = useRef(null);
  const {
    court = "",
    drawerState,
    newBookings,
    getPaymentDetails,
    sendBookingToServerAsAdmin,
    setDrawerState,
    setShowLogin,
    setTotalPrice,
  } = useContext(BookingsContext);

  const { isLoggedIn } = useContext(UserContext);

  useEffect(() => {
    const generatePrices = async () => {
      const { data: { prices = [] } = {} } = await axios.post(
        "prices/generate_price",
        newBookings
      );
      const finalPrice = prices.reduce(priceReducer, {
        dayTime: { count: 0, price: 0 },
        nightTime: { count: 0, price: 0 },
        total: 0,
      });

      finalPrice["bookings"] = newBookings.map((el) => ({
        start: el.start_date,
        end: el.end_date,
      }));

      setPrice(finalPrice);
    };

    generatePrices();
  }, [newBookings]);

  const handleBookings = async () => {
    setTotalPrice(price.total);
    if (isLoggedIn && !hasTokenExpired()) {
      await getPaymentDetails();
    } else {
      setShowLogin(true);
    }
  };

  const handleAdminBookings = async () => {
    //add notes and send bookings to server

    if (isAdmin) {
      await sendBookingToServerAsAdmin(notesRef.current.state.value);
      return;
    }
  };

  const CustomerView = () => (
    <div className={classes.customerView}>
      <Row type="flex" justify="center">
        <Button
          type="primary"
          onClick={handleBookings}
          className={classes.bookingButton}
          disabled={bookings.length === 0}
        >
          REZERVĂ
        </Button>
      </Row>
    </div>
  );

  const AdminView = () => (
    <div className={classes.adminView}>
      <Input.TextArea
        className={classes.notes}
        placeholder="Adauga mentiuni"
        ref={notesRef}
        rows={6}
      ></Input.TextArea>
      <Row type="flex" justify="center">
        <Button
          type="primary"
          onClick={handleAdminBookings}
          className={classes.bookingButton}
          disabled={bookings.length === 0}
        >
          REZERVĂ
        </Button>
      </Row>
    </div>
  );

  const { dayTime = 0, nightTime = 0, bookings = [], total = 0 } = price;
  return (
    <Fallback loading={false} fallback={<div></div>}>
      <Drawer
        className={classes.drawer}
        visible={drawerState.show}
        mask={false}
        closable={drawerState.mobile}
        onClose={() => setDrawerState((prev) => ({ ...prev, show: false }))}
        width="300px"
      >
        <h1 className={classes.title}>Rezervarea mea</h1>

        <h2>{court.name}</h2>

        <div className={classes.bookingsContainer}>
          {bookings.map((el) => (
            <div className={classes.booking} key={el.start}>
              <span className={classes.date}>
                {moment(el.start).locale("ro").format("D MMM")}
              </span>
              <span className={classes.hour}>
                {moment(el.start).locale("ro").format("HH:mm")} -{" "}
                {moment(el.end).locale("ro").format("HH:mm")}
              </span>
            </div>
          ))}
        </div>

        <div>
          <h3>Tarif</h3>

          <div className={classes.priceContainer}>
            <span>
              {dayTime.count} {dayTime.count === 1 ? "oră zi" : "ore zi"}
            </span>
            <span>
              ({dayTime.count} X {dayTime.price} lei)
            </span>
          </div>
          <div className={classes.priceContainer}>
            <span>
              {nightTime.count}{" "}
              {nightTime.count === 1 ? "oră nocturnă" : "ore nocturne"}
            </span>
            <span>
              ({nightTime.count} X {nightTime.price} lei)
            </span>
          </div>
        </div>
        <div className={classes.totalContainer}>
          <h2>Total: </h2>
          <h2>{total} lei</h2>
        </div>

        {isAdmin ? <AdminView></AdminView> : <CustomerView></CustomerView>}

        <div className={classes.logoContainer}>
          <img className={classes.logo} src={logo} alt="logo" />
        </div>
      </Drawer>
    </Fallback>
  );
};

export default React.memo(PriceDrawer);
