import React, { useState, useEffect } from "react";
import { Modal, Button, notification } from "antd";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interaction from "@fullcalendar/interaction";
import localeRO from "@fullcalendar/core/locales/ro";

import classes from "./Scheduler.module.scss";
import useScreenSize from "../useScreenSize";

const moment = require("moment");

const uuid = require("uuid/v4");

const tzcheck = Intl.DateTimeFormat().resolvedOptions().timeZone === "Europe/Bucharest"
if (!tzcheck) {
  notification.error({
      message: "Eroare",
      description: "Pentru a putea face rezervari online, ora locală a dispozitivului dumneavoastră trebuie să fie cea a României",
      duration: 0
    });
}

const Scheduler = ({
  existingBookings,
  userBookings,
  setUserBookings,
  isAdmin,
  deleteBookingAsAdmin,
}) => {
  const screenSize = useScreenSize();
  const [schedulerViewType, setSchedulerViewType] = useState("timeGridDay");

  useEffect(() => {
    const updatedSchedulerView =
      screenSize.width < 900 ? "timeGridDay" : "timeGridWeek";
    setSchedulerViewType(updatedSchedulerView);
  }, [screenSize.width]);

  const handleIntervalClick = ({ start, end }) => {
    var duration = moment.duration(moment(end).diff(start));
    var hoursDifference = duration.asHours();
    if (hoursDifference === 1) {
      return;
    }
    const newAppointment = {
      id: uuid(),
      start,
      end,
      className: [classes.event],
      color: "#69c516",
      title: `${moment(start).format("HH")}:00 - ${moment(end).format(
        "HH"
      )}:00`,
    };
    const updatedUserAppointments = [...userBookings, newAppointment];
    setUserBookings(updatedUserAppointments);
  };

  const notz = () => {
      notification.error({
        message: "Eroare",
        description: "Pentru a putea face rezervari online, ora locală a dispozitivului dumneavoastră trebuie să fie cea a României"
      });
  };

  const handleDateClick = ({ dateStr }) => {
    const endDate = moment(dateStr).add(1, "h");
    const newAppointment = {
      id: uuid(),
      start: dateStr,
      end: endDate,
      className: [classes.event],
      color: "#69c516",
      title: `${moment(dateStr).format("HH")}:00 - ${moment(endDate).format(
        "HH"
      )}:00`,
    };
    const updatedUserAppointments = [...userBookings, newAppointment];
    setUserBookings(updatedUserAppointments);
  };

  const handleWindowResize = (view) => {
    view.type = "timeGridDay";
  };

  const handleAppointmentClick = (event) => {
    const { id } = event;

    if (isAdmin) {
      const eventToBeRemoved = existingBookings.find((e) => e.id === event.id);
      if (eventToBeRemoved) {

        function onClick() {
          modal.update({
            okButtonProps: {disabled: false}
          })
        }

        const modal = Modal.confirm({
          className: classes.confirmationModal,
          icon: false,
          title: `${event.title}`,
          content: (<>
          {moment(event.start).format("dddd, Do MMMM")}
          <br />
          {moment(event.start).format("HH:00")} - {moment(event.end).format("HH:00")}
          <br />
          <br />
          <Button block type="dashed" onClick={onClick}>Click pentru a activa butonul STERGE</Button>
          </>),
          okText: "STERGE",
          cancelText: "PASTREAZA",
          okType: 'danger',
          okButtonProps: {disabled: true},
          maskClosable: true,

          afterClose: Modal.destroyAll(),
          onOk: () => deleteBookingAsAdmin(event)
        })

        if (event.title === 'hold' || event.title === 'hold_') {
          modal.update({
            okButtonProps: {disabled: false},
            content: (<>
              {moment(event.start).format("dddd, Do MMMM")}
              <br />
              {moment(event.start).format("HH:00")} - {moment(event.end).format("HH:00")}
              <br />
              <br />
              </>)
          })
        }
      }
    }

    const updatedUserAppointments = userBookings.filter(
      (appointment) => appointment.id !== id
    );
    setUserBookings(updatedUserAppointments);
  };

  const handleSelection = (info) => {
    return (
      !moment(info.start).isBefore() && 
      moment(info.start).isSame(moment(info.end), "day")
    );
  };

  const headerDateFormat = (date) => moment(date).format("dddd [\n] DD MMMM");
  const headerDateFormatMobile = (date) => moment(date).format("dddd DD MMMM");

  const validRange = !isAdmin
    ? {
        start: Date.now(),
        end: moment().add(13, "d").format(),
      }
    : {
        start: Date.now(),
        end: moment().add(2, "y").format(),
      };
  return (
    <div className={classes.schedulerContainer}>
      <FullCalendar
        plugins={[timeGridPlugin, interaction]}
        defaultView={screenSize.width < 900 ? "timeGridDay" : "timeGridWeek"}
        contentHeight={"auto"}
        locale={localeRO}
        // timeZone={"Europe/Bucharest"}
        allDaySlot={false}
        columnHeaderText={
          screenSize.width < 900 ? headerDateFormatMobile : headerDateFormat
        }
        buttonText={{
          today: "Astăzi",
          prev: "< Înapoi",
          next: "Înainte >",
        }}
        header={{
          left: "",
          center: "title",
          right: "prev,today,next",
        }}
        footer={{
          left: "prev,today,next",
          center: "",
          right: "",
        }}
        slotDuration={"01:00"}
        minTime={"08:00"}
        maxTime={"23:00"}
        windowResize={(view) => handleWindowResize(view)}
        selectAllow={tzcheck ? handleSelection : false}
        firstDay={1}
        events={[...existingBookings, ...userBookings]}
        displayEventTime={false}
        selectable={tzcheck ? true : false}
        select={(info) => handleIntervalClick(info)}
        eventClick={({ event }) => handleAppointmentClick(event)}
        dateClick={tzcheck ? handleDateClick : notz}
        selectOverlap={false}
        selectLongPressDelay={0}
        validRange={validRange}
      />
      <Modal></Modal>
    </div>
  );
};

export default React.memo(Scheduler);
