import React from "react";
import { DatePicker, Input, Select, Col, Row } from "antd";
import { useDebouncedCallback } from "use-lodash-debounce";
import classes from "./BookingsFilter.module.scss";

const { Option } = Select;

const BookingsFilter = ({ setName, setCourt, setDate, courts }) => {
  const debouncedName = useDebouncedCallback(setName, 800);
  return (
    <Row gutter={16} className={classes.container}>
      <Col xs={18} sm={8} lg={4}>
        <DatePicker
          className={classes.datePicker}
          onChange={date => setDate(date)}
        ></DatePicker>
      </Col>
      <Col xs={18} sm={8} lg={8} xl={6}>
        <Input
          placeholder="Nume sau mentiuni"
          onChange={evt => debouncedName(evt.target.value)}
        ></Input>
      </Col>
      <Col xs={18} sm={8} lg={4}>
        <Select
          style={{ width: "100%" }}
          onChange={setCourt}
          placeholder="Teren"
        >
          {courts &&
            Object.values(courts).map(el => (
              <Option value={el} key={el}>
                {el}
              </Option>
            ))}
          <Option value="">Toate</Option>
        </Select>
      </Col>
    </Row>
  );
};

export default BookingsFilter;
