import React, { useState } from "react";
import Booking from "./Booking/Booking";
import { Col, Row, notification } from "antd";
import BookingsFilter from "./BookingsFilter/BookingsFilter";
import moment from "moment";
import { useEffect } from "react";
import axios from "../../../axiosAuthorized.config";

const BookingsList = () => {
  const [filters, setFilters] = useState({ name: "", court: "" });
  const [date, setDate] = useState(Date.now());
  const [courts, setCourts] = useState();
  const [bookings, setBookings] = useState();

  useEffect(() => {
    const getCourts = async () => {
      const { data } = await axios.get("/courts");
      const mappedCourts = data.courts.reduce((acc, curr) => {
        acc[curr.id] = curr.name;
        return acc;
      }, {});
      setCourts(mappedCourts);
      getBookings(mappedCourts);
    };

    getCourts();
  }, []);

  useEffect(() => {
    if (courts) {
      getBookings(courts);
    }
  }, [date]);

  const getBookings = async courts => {
    const formatedDate = moment(date).format("YYYY-MM-DD");
    const {
      data: { bookings }
    } = await axios(`/admin/bookings/${formatedDate}`);

    const mappedBookings = bookings.map(el => ({
      ...el,
      notes: el.notes || "",
      start: moment(el.start_date).format("ha"),
      end: moment(el.end_date).format("ha"),
      court: courts[el.court_id]
    }));
    setBookings(mappedBookings);
  };

  const criteria = booking => {
    const { name, court } = filters;
    return (
      (booking.username.toLowerCase().includes(name.toLowerCase()) ||
        booking.notes.toLowerCase().includes(name.toLowerCase())) &&
      booking.court.includes(court)
    );
  };

  const handleRemoveBooking = async (court_id, start_date) => {
    try {
      const data = await axios.delete("/admin/bookings", {
        data: {
          court_id,
          start_date
        }
      });
      const updatedList = bookings.filter(
        el =>
          el.court_id !== court_id ||
          moment(el.start_date).format() !== moment(start_date).format()
      );
      setBookings(updatedList);
      notification.success({ message: "Programarea a fost anulată cu succes" });
    } catch (err) {
      notification.error({
        message: "Am întâmpinat o problemă.",
        description: "Vă rugăm încercați mai târziu"
      });
    }
  };

  return (
    <div style={{ margin: "24px" }}>
      <BookingsFilter
        courts={courts}
        setDate={setDate}
        setName={name => setFilters(prev => ({ ...prev, name }))}
        setCourt={court => setFilters(prev => ({ ...prev, court }))}
      ></BookingsFilter>

      <Row gutter={16} type="flex">
        {bookings &&
          bookings.filter(criteria).map(el => (
            <Col
              xs={24}
              lg={12}
              style={{ marginBlockEnd: "16px" }}
              key={el.court_id + el.start_date}
            >
              <Booking {...el} handleRemoveBooking={handleRemoveBooking} />
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default BookingsList;
