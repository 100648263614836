import React, { useContext } from "react";
import { Modal, Icon, Row, Button, Col } from "antd";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import FacebookProvisional from "react-facebook-login";
import classes from "./LoginModal.module.scss";
import { BookingsContext } from "../../../Context/BookingsContext";
import { googleId, facebookId } from "../../../globals";
import axios from "../../../axios.config";
import { loginSVG } from "../../../globals";
import { storeToken } from "../../../auth";
import { UserContext } from "../../../Context/UserContext";

const LoginModal = () => {
  const { setShowLogin, getPaymentDetails } = useContext(BookingsContext);
  const { setIsLoggedIn } = useContext(UserContext);

  const handleLogin = async (path, token) => {
    try {
      const {
        data: { accessToken },
      } = await axios.post(path, { token });
      storeToken(accessToken);
      setIsLoggedIn(true);

      await getPaymentDetails();
      setShowLogin(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      visible={true}
      width="680px"
      bodyStyle={{
        padding: "48px",
        paddingLeft:
          "calc(26px + (48 - 26) * ((100vw - 300px) / (1920 - 300)))",
        paddingRight:
          "calc(26px + (48 - 26) * ((100vw - 300px) / (1920 - 300)))",
        position: "relative",
        minHeight: "350px",
        overflow: "hidden",
        borderRadius: "8px",
      }}
      footer={false}
      onCancel={() => setShowLogin(false)}
      className={classes.modal}
    >
      <h2 className={classes.title}>
        Vă rugăm să vă autentificați folosind una dintre opțiunile de mai jos
      </h2>
      <h3 className={classes.modalbodytext}>
        Avem nevoie de un nume și de o adresă de email validă pentru a vă putea
        confirma identitatea in cazul in care aveți nevoie să vă
        anulați/schimbați programarea.&nbsp;
      </h3>
      <h3 className={classes.highlight}>
        Nu trimitem niciodată email-uri de marketing la această adresă.
      </h3>

      <h3 className={classes.modalsmalltext}>
        Prin continuare confirmați că sunteți de acord cu{" "}
        <a href="/terms-and-conditions" target="_blank">
          termenii și condițiile de utilizare
        </a>{" "}
        și cu{" "}
        <a href="/privacy-policy" target="_blank">
          politica de confidențialitate
        </a>
        .
      </h3>

      <Row style={{ position: "static" }}>
        <Col xs={24} md={12} className={classes.buttonsContainer}>
          <GoogleLogin
            clientId={googleId}
            buttonText="Login"
            onSuccess={({ tokenId }) => handleLogin("/login/google", tokenId)}
            onFailure={console.log}
            cookiePolicy={"single_host_origin"}
            render={(renderProps) => (
              <Button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                icon="google"
                type="primary"
                className={classes.googleButton}
              >
                Continue with google
              </Button>
            )}
          />

          <Button disabled icon="user" className={classes.guestCheckout}>
            Guest (coming later)
          </Button>

          {/* <FacebookProvisional
            appId={facebookId}
            autoLoad={true}
            fields="name,email,picture"
            callback={console.log}
            buttonStyle={{ display: "none" }}
          /> */}
          {/* <FacebookLogin
            appId={facebookId}
            autoLoad={false}
            callback={({ accessToken }) =>
              handleLogin("/login/facebook", accessToken)
            }
            fields="name,email,picture"
            render={(renderProps) => (
              <Button
                type="primary"
                icon="facebook"
                className={classes.facebookButton}
                onClick={renderProps.onClick}
              >
                Continue with facebook
              </Button>
            )}
          /> */}
        </Col>

        <Col span={12} style={{ position: "static" }}>
          <img className={classes.svg} src={loginSVG} alt="illustration" />
        </Col>
      </Row>
    </Modal>
  );
};

export default LoginModal;
