import React from "react";

import { visa, mastercard } from "../../../globals";
import classes from "./PaymentForm.module.scss";

const CardVendors = () => {
  return (
    <div className={classes.cardVendorContainer}>
      <img 
        className={classes.cardVendor} 
        src={visa}
        alt="visa-logo"/>
      <img
        className={classes.cardVendor}
        src={mastercard}
        alt="mastercard-logo"
      />
    </div>
  );
};

export default CardVendors;
