import React from "react";
import { useContext } from "react";
import { UserContext } from "../../../Context/UserContext";
import { Redirect } from "@reach/router";

export const AdminRoute = ({ component: Component, ...rest }) => {
  const { isAdmin } = useContext(UserContext);
  return isAdmin ? (
    <Component {...rest}></Component>
  ) : (
    <Redirect to="/config/login" noThrow></Redirect>
  );
};
