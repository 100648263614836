import React from "react";
import Header from "./Header";
import TermsContent from "./TermsContent";
import LegalButtons from "./LegalButtons";
import Contact from "../LandingPage/Contact/Contact";
import classes from "./Legal.module.scss"

const TermsAndConditions = () => {
  return (
    <>
      <Header />
      <div className={classes.container}>
        <LegalButtons></LegalButtons>
        <TermsContent></TermsContent>
      </div>
      <Contact></Contact>
    </>
  );
};

export default TermsAndConditions;
