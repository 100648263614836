import React from "react";
import { logoColor } from "../../globals";
import classes from "./Header.module.scss";

const Header = () => {
  return (
    <div className={classes.header}>
      <a href="/">
        <img src={logoColor} alt="logo" className={classes.logo} />
      </a>
      <p className={classes.title}>CLUB iTENIS IAȘI</p>
    </div>
  );
};

export default Header;
