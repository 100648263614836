import React, { useState } from "react";
import CourtButtons from "./CourtButtons/CourtButtons";
import { Row, Col } from "antd";
import CourtScheduler from "./CourtScheduler/CourtScheduler";
import BookingsManager from "./BookingsManager/BookingsManager";
import LoginModal from "./CustomerLogin/LoginModal";
import PaymentForm from "./PaymentForm/PaymentForm";
import { StripeProvider, Elements } from "react-stripe-elements";
import PageHeader from "./PageHeader/PageHeader";
import PriceDrawer from "./PriceDrawer/PriceDrawer";
import { stripeKey } from "../../globals";

import classes from "./CustomerPage.module.scss";

const CustomerPage = () => {
  const [showLogin, setShowLogin] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  return (
    <div>
      <PageHeader></PageHeader>
      <BookingsManager
        setShowLogin={setShowLogin}
        setShowPaymentForm={setShowPaymentForm}
      >
        <Row>
          <Col
            // span={24}
            style={{
              margin: "0 24px",
            }}
          >
            <CourtButtons></CourtButtons>
          </Col>
        </Row>
        <Row className={classes.schedulerContainer}>
          <CourtScheduler></CourtScheduler>
          <PriceDrawer></PriceDrawer>
        </Row>
        {showLogin && <LoginModal></LoginModal>}
        {showPaymentForm && (
          <StripeProvider apiKey={stripeKey}>
            <Elements>
              <PaymentForm></PaymentForm>
            </Elements>
          </StripeProvider>
        )}
      </BookingsManager>
    </div>
  );
};

export default CustomerPage;
