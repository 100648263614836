import React, { useState, useEffect } from "react";
import { BookingsContext } from "../../../Context/BookingsContext";
import axios from "../../../axios.config";
import moment from "moment";
import { notification } from "antd";
import axiosAuthorized from "../../../axiosAuthorized.config";
import { getParsedToken } from "../../../auth";
import classes from "../../Common/Scheduler/Scheduler.module.scss";
import { useContext } from "react";
import { UserContext } from "../../../Context/UserContext";

const uuid = require("uuid/v4");

const toSchedulerFormatAdmin = (bookings) => ({
  id: uuid(),
  start: moment(bookings.start_date).format(),
  end: moment(bookings.end_date).format(),
  color: "#cb5223",
  className: [classes.event],
  title: `${
    !!bookings.payment_intent_id
      ? `($) ${bookings.username} ($)`
      : `${bookings.notes}`
  }`,
});

const toSchedulerFormat = (bookings) => ({
  id: uuid(),
  start: moment(bookings.start_date).format(),
  end: moment(bookings.end_date).format(),
  color: `${
    !!(moment(bookings.start_date).format("ss") === "01")
      ? "#808080"
      : "#cb5223"
  }`,
  className: [classes.event],
  title: `${
    !!(moment(bookings.start_date).format("ss") === "01")
      ? "Indisponibil"
      : `${moment(bookings.start_date).format("HH")}:00 - ${moment(
        bookings.end_date
      ).format("HH")}:00 Rezervat`
  }`,
});


const getDisabledHoursEvent = () => {
  const presentRoundedHour = moment()
    .add(1, "h")
    .minutes(0)
    .seconds(0)
    .format();
  const maxHour = moment().hours(23).minutes(0).seconds(0).format();
  const end = moment(maxHour).isAfter(presentRoundedHour)
    ? presentRoundedHour
    : maxHour;

  const disabledHours = {
    id: uuid(),
    start: moment().hours(8).minutes(0).seconds(0).format(),
    end: end,
    className: [classes.disabledHours],
    display: "background",
  };

  return disabledHours;
};

const BookingsManager = ({ setShowLogin, setShowPaymentForm, children }) => {
  const [court, setCourt] = useState("");
  const [existingBookings, setExistingBookings] = useState([]);
  const [drawerState, setDrawerState] = useState({
    show: window.innerWidth > 768,
    mobile: window.innerWidth < 768,
  });
  const [userBookings, setUserBookings] = useState([]);
  const [newBookings, setNewBookings] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState({
    clientSecret: "",
    paymentId: "",
  });
  const [totalPrice, setTotalPrice] = useState(0);
  const { isAdmin } = useContext(UserContext);

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth: width } = window;
      if (width < 768 && drawerState.show) {
        setDrawerState({ show: false, mobile: true });
      }
      if (width > 768 && !drawerState.show) {
        setDrawerState({ show: true, mobile: false });
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [drawerState.show]);

  useEffect(() => {
    const { courtId } = court;

    const getEventsAsAdmin = async () => {
      const {
        data: { bookings },
      } = await axiosAuthorized.get(`admin/bookings?court_id=${courtId}`);
      const mappedData = bookings.map(toSchedulerFormatAdmin);
      setExistingBookings(mappedData);
    };

    isAdmin ? getEventsAsAdmin() : getEvents();
    setUserBookings([]);
  }, [court]);

  useEffect(() => {
    setNewBookings(
      userBookings.map((appointment) => ({
        court_id: court.courtId,
        start_date: moment(appointment.start).format(),
        end_date: moment(appointment.end).format(),
      }))
    );
  }, [userBookings]);

  const getEvents = async () => {
    const { courtId } = court;
    const disabledHours = getDisabledHoursEvent();
    if (courtId) {
      const { data } = await axios.get(`bookings/${courtId}`);
      const mappedData = data.map(toSchedulerFormat);
      setExistingBookings([...mappedData, disabledHours]);
    }
  };

  const handleBookingsCleanup = () => {
    axiosAuthorized.post("clean-up/async", {
      payment_id: paymentDetails.paymentId,
    });
  };

  const cleanupOnPaymentFail = () => {
    const bookingsToBeRemoved = newBookings.map((el) => ({
      court_id: el.court_id,
      start_date: el.start_date,
    }));
    axiosAuthorized.post("clean-up/async", {
      bookings: bookingsToBeRemoved,
    });
  };

  const getPaymentDetails = async () => {
    try {
      const {
        data: { client_secret, id },
      } = await axiosAuthorized.post("payment", newBookings);
      setPaymentDetails({ clientSecret: client_secret, paymentId: id });

      setShowPaymentForm(true);
    } catch (err) {
      notification.error({
        message: "Oops! Something went wrong",
        description: "Please try again later",
      });
    }
  };

  const sendBookingsToServer = async () => {
    const { paymentId } = paymentDetails;
    const bookingsPayload = {
      bookings: newBookings,
      payment_id: paymentId,
    };
    await axiosAuthorized.post("bookings", bookingsPayload);
  };

  const sendBookingToServerAsAdmin = async (notes) => {
    try {
      //use axiosAuthorized
      const requestBody = newBookings.map((el) => ({ ...el, notes }));
      await axiosAuthorized.post("/admin/bookings", requestBody);
      cleanupOnPaymentSucceeded();
      notification.success({
        message: "Programarea a fost facută cu succes!",
      });
    } catch (err) {
      notification.error({
        message: "Rezervare eșuată!",
        description: "Altcineva tocmai a rezervat intervalul ales.",
      });
    }
  };

  const cleanupOnPaymentSucceeded = () => {
    const processedBookings = userBookings.map((booking) => ({
      ...booking,
      color: "#cb5223",
      title: `${moment(booking.start).format("HH")}:00 - ${moment(
        booking.end
      ).format("HH")}:00 Rezervat`,
    }));

    setExistingBookings((allBookings) => [
      ...allBookings,
      ...processedBookings,
    ]);
    setUserBookings([]);
  };

  const deleteBookingAsAdmin = async (event) => {
    const { id, start } = event;

    try {
      await axiosAuthorized.delete("/admin/bookings", {
        data: {
          court_id: court.courtId,
          start_date: moment(start).format(),
        },
      });
      const updatedList = existingBookings.filter((e) => e.id !== id);
      setExistingBookings(updatedList);
    } catch (err) {}
  };

  return (
    <BookingsContext.Provider
      value={{
        cleanupOnPaymentSucceeded,
        cleanupOnPaymentFail,
        deleteBookingAsAdmin,
        court,
        drawerState,
        existingBookings,
        getEvents,
        getPaymentDetails,
        handleBookingsCleanup,
        newBookings,
        paymentDetails,
        setCourt,
        sendBookingToServerAsAdmin,
        sendBookingsToServer,
        setDrawerState,
        setExistingBookings,
        setPaymentDetails,
        setShowLogin,
        setShowPaymentForm,
        setTotalPrice,
        setUserBookings,
        totalPrice,
        userBookings,
      }}
    >
      {children}
    </BookingsContext.Provider>
  );
};

export default BookingsManager;
