import React, { Component } from "react";
import "./App.css";
import CustomerPage from "./Components/CustomerPage/CustomerPage";
import UserManager from "./Components/Common/UserManager/UserManager";
import { Router } from "@reach/router";
import LandingPage from "./Components/LandingPage/LandingPage";
import Login from "./Components/Admin/Login/Login";
import AdminLayout from "./Components/Admin/AdminLayout/AdminLayout";
import { AdminRoute } from "./Components/Common/Routing/Routing";
import TermsAndConditions from "./Components/Legal/TermsAndConditions";
import Privacy from "./Components/Legal/Privacy";

class App extends Component {
  render() {
    return (
      <>
        <Router>
          <UserManager default>
            <CustomerPage path="/bookings"></CustomerPage>
            <Login path="/config/login"></Login>
            <TermsAndConditions path="/terms-and-conditions" />
            <Privacy path="/privacy-policy" />
            <AdminRoute component={AdminLayout} path="/admin/*" />
          </UserManager>
          <LandingPage path="/"></LandingPage>
        </Router>
      </>
    );
  }
}

export default App;
