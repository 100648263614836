import React from "react";
import { Card, Icon, Col, Button, Popconfirm } from "antd";
import classes from "./Booking.module.scss";


const Booking = ({
  court_id,
  username: name,
  start,
  end,
  start_date,
  notes,
  payment_intent_id,
  court,
  handleRemoveBooking
}) => {
  return (
    <Card className={classes.card}>
      <Col xs={24} md={3} className={classes.fieldContainer}>
        <p>Nume</p>
        <p>{name}</p>
      </Col>
      <Col xs={24} md={3} className={classes.fieldContainer}>
        <p>Teren</p>
        <p>{court}</p>
      </Col>
      <Col xs={24} md={3} className={classes.fieldContainer}>
        <p>Inceput</p>
        <p>{start}</p>
      </Col>
      <Col xs={24} md={3} className={classes.fieldContainer}>
        <p>Sfarsit</p>
        <p>{end}</p>
      </Col>
      <Col xs={24} md={7} className={classes.fieldContainer}>
        <p>Mentiuni</p>
        <p>{notes}</p>
      </Col>
      <Col
        xs={24}
        md={3}
        style={{ textAlign: "center" }}
        className={classes.fieldContainer}
      >
        <p>Met. plata</p>
        {!!payment_intent_id ? (
          <Icon type="credit-card"></Icon>
        ) : (
          <Icon type="user"></Icon>
        )}
      </Col>
      <Col span={1} className={classes.buttonContainer}>
        <Popconfirm
          okType={"default"}
          cancelText="Anulează"
          onConfirm={() => handleRemoveBooking(court_id, start_date)}
          title="Sunteți sigur că vreți să ștergeți programarea?"
        >
          <Button icon="delete"></Button>
        </Popconfirm>
      </Col>
    </Card>
  );
};

export default Booking;
