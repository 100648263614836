import React, { useContext } from "react";
import Scheduler from "../../Common/Scheduler/Scheduler";
import { BookingsContext } from "../../../Context/BookingsContext";
import Fallback from "../../Common/Fallback/Fallback";
import { Button, notification } from "antd";
import { UserContext } from "../../../Context/UserContext";
import classes from "./CourtScheduler.module.scss";

const CourtScheduler = () => {
  const {
    existingBookings,
    userBookings,
    setUserBookings,
    court,
    setDrawerState,
    deleteBookingAsAdmin,
  } = useContext(BookingsContext);
  const { isAdmin } = useContext(UserContext);

  const { courtId } = court;

  const handleBookingMobile = () => {
    if (userBookings.length) {
      setDrawerState((prev) => ({ ...prev, show: true }));
      return;
    }
    const NotificationMessage = () => (
      <img
        className={classes.demoGif}
        src={"./images/bookprocessdemo.gif"}
        alt="gif"
      />
    );
    notification.open({
      message: "Selectați un interval disponibil din calendar",
      description: <NotificationMessage />,
      duration: 6,
    });
  };

  return (
    <div>
      <Fallback loading={!courtId} fallback={<div></div>}>
        <Scheduler
          existingBookings={existingBookings}
          userBookings={userBookings}
          setUserBookings={setUserBookings}
          isAdmin={isAdmin}
          deleteBookingAsAdmin={deleteBookingAsAdmin}
        ></Scheduler>
        <div>
          <Button className={classes.bookButton} onClick={handleBookingMobile}>
            REZERVĂ
          </Button>
        </div>
      </Fallback>
    </div>
  );
};

export default CourtScheduler;
