import React from "react";
import { logoColor } from "../../../globals";
import { navigate } from "@reach/router";
import classes from "./HomePage.module.scss";
import { Row, Col, Button, Icon } from "antd";
import Nav from "./Nav/Nav";

const HomePage = () => {
  return (
    <div className={classes.container}>
      <Row className={classes.headerContainer}>
        <Col
          className={classes.clubInfo}
          xs={{ span: 22, offset: 1 }}
          md={{ span: 10, offset: 2 }}
          xl={{ span: 8, offset: 2 }}
        >
          <img src={logoColor} alt="logo" className={classes.logo} />
          <div className={classes.nameContainer}>
            <h1>
              Club <span style={{ textTransform: "lowercase" }}>i</span>Tenis
              Iași
            </h1>
          </div>
        </Col>
        <Col
          xs={{ span: 22, offset: 1 }}
          md={{ span: 10, offset: 0 }}
          xl={{ span: 12, offset: 0 }}
          className={classes.navContainer}
        >
          <Nav></Nav>
        </Col>
      </Row>
      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          md={{ span: 9, offset: 2 }}
          lg={{ span: 7, offset: 2 }}
          xl={{ span: 5, offset: 2 }}
          className={classes.infoContainer}
        >
          <h3>
            <span>
              CLUB <span style={{ textTransform: "lowercase" }}>i</span>Tenis
            </span>{" "}
            IAȘI
          </h3>
          <h1>
            patru terenuri de zgură dintre care două acoperite și încălzite în
            sezonul rece
          </h1>
          <Button
            className={classes.button}
            type="primary"
            onClick={() => navigate("/bookings")}
          >
            REZERVĂ TEREN ONLINE
          </Button>
        </Col>

        <Col
          xs={{ span: 22, offset: 1 }}
          md={{ span: 9, offset: 4 }}
          lg={{ span: 7, offset: 6 }}
          xl={{ span: 5, offset: 10 }}
          className={classes.socialMedia}
        >
          <div><a href="http://www.facebook.com/iTenisIasi" target="_blank"><Icon className={classes.icon} type="facebook" theme="filled"/> <h2 className={classes.fblink}>facebook.com/iTenisIasi</h2></a></div>
          <div className={classes.fb}>
            <div
              className="fb-page"
              data-href="https://www.facebook.com/iTenisIasi/"
              data-width="380"
              data-hide-cover="false"
              data-show-facepile="false"
            ></div>
          </div>
          <h2 className={classes.description}>
            Urmăriți-ne pagina de Facebook pentru informații despre evenimentele
            și campionatele organizate de clubul nostru
          </h2>
        </Col>
      </Row>
    </div>
  );
};

export default HomePage;
