import React from "react";
import { Card, Checkbox, Button } from "antd";
import classes from "./CourtCard.module.scss";

const CourtCard = ({ court, price, setModalState }) => {
  const { name, type, active } = court;
  const { day_value, night_value } = price;

  const handleEdit = () => {
    setModalState({ court, price, show: true, newCourt: false });
  };

  return (
    <Card className={classes.card}>
      <p>
        Nume: <span>{name}</span>
      </p>
      <p>
        Tip: <span>{type}</span>
      </p>
      <p>
        Activ <Checkbox checked={active} disabled={true}></Checkbox>
      </p>
      <p>
        Preț zi: <span>{day_value} RON</span>
      </p>
      <p>
        Preț nocturnă: <span>{night_value} RON</span>
      </p>
      <Button
        icon="edit"
        className={classes.editButton}
        onClick={handleEdit}
      ></Button>
    </Card>
  );
};

export default CourtCard;
