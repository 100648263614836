import React from "react";
import { Menu, Icon } from "antd";
import { navigate } from "@reach/router";
import { logOut } from "../../../auth";
import classes from "./AdminHeader.module.scss";
import { useState } from "react";
const classNames = require("classnames");

const AdminHeader = () => {
  const handleLogOut = () => {
    logOut();
    navigate("/admin/login");
  };

  const [menuKey, setKey] = useState("");

  const isActive = name => classNames({ [classes.active]: name === menuKey });

  return (
    <Menu
      mode="horizontal"
      className={classes.header}
      onSelect={({ key }) => setKey(key)}
    >
      <Menu.Item
        key="schedule"
        className={isActive("schedule")}
        onClick={() => {
          navigate("/admin/add-bookings");
        }}
      >
        <Icon type="table" />
        Orar
      </Menu.Item>
      <Menu.Item
        key="bookings"
        className={isActive("bookings")}
        onClick={() => {
          navigate("/admin/bookings");
        }}
      >
        <Icon type="schedule" />
        Programări
      </Menu.Item>
      <Menu.Item
        key="courts"
        className={isActive("courts")}
        onClick={() => {
          navigate("/admin/courts");
        }}
      >
        <Icon type="control"></Icon>
        Terenuri
      </Menu.Item>
      <Menu.Item key="logOut" onClick={() => handleLogOut()}>
        <Icon type="poweroff" />
        Deconeteaza-te
      </Menu.Item>
    </Menu>
  );
};

export default AdminHeader;
