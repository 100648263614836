import React from "react";
import classes from "./Login.module.scss";
import { Card, Form, Input, Col, Row, Button } from "antd";
import axios from "../../../axios.config";
import { useState } from "react";
import { storeToken } from "../../../auth";
import { useContext } from "react";
import { UserContext } from "../../../Context/UserContext";
import { navigate } from "@reach/router";

const Login = ({ form }) => {
  const { getFieldDecorator, getFieldsValue } = form;
  const [error, setError] = useState(false);
  const { setIsLoggedIn } = useContext(UserContext);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const credentials = getFieldsValue();
      const {
        data: { accessToken }
      } = await axios.post("admin/login", credentials);
      storeToken(accessToken);
      setIsLoggedIn(true);
      navigate("/admin/add-bookings");
    } catch (err) {
      setError(true);
    }
  };
  return (
    <div className={classes.container}>
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 18, offset: 3 }}
        md={{ span: 12, offset: 6 }}
        lg={{ span: 8, offset: 8 }}
        xl={{ span: 6, offset: 9 }}
      >
        <Card>
          <Form onSubmit={handleSubmit}>
            {error && (
              <p className={classes.error}>Credențialele sunt incorecte</p>
            )}
            <Form.Item label="Nume">
              {getFieldDecorator("username")(<Input></Input>)}
            </Form.Item>
            <Form.Item label="Parolă">
              {getFieldDecorator("password")(<Input type="password"></Input>)}
            </Form.Item>

            <Row type="flex" justify="center">
              <Button htmlType="submit">Trimite</Button>
            </Row>
          </Form>
        </Card>
      </Col>
    </div>
  );
};

export default Form.create()(Login);
