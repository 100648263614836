import React from "react";
import { Row, Col, Button } from "antd";
import CourtCard from "./CourtCard/CourtCard";
import CourtModal from "./CourtModal/CourtModal";
import { useState } from "react";
import axios from "../../../axiosAuthorized.config";
import { useEffect } from "react";

const Courts = () => {
  const [modalState, setModalState] = useState({
    show: false,
    court: {},
    price: {},
    newCourt: true
  });

  const [courts, setCourts] = useState();

  useEffect(() => {
    const getCourts = async () => {
      const {
        data: { courts }
      } = await axios.get("/admin/courts");
      setCourts(courts);
    };
    getCourts();
  }, []);

  return (
    <div style={{ padding: "24px" }}>
      <Row type="flex" justify="end" style={{ marginBlockEnd: "16px" }}>
        <Button
          type=" default"
          icon="plus"
          onClick={() => setModalState(prev => ({ ...prev, show: true }))}
        >
          Adaugă teren
        </Button>
      </Row>
      <Row gutter={16} type={"flex"}>
        {courts &&
          courts.map(el => (
            <Col
              xs={24}
              sm={12}
              md={8}
              lg={6}
              key={el.court.id}
              style={{ marginBlockEnd: "16px" }}
            >
              <CourtCard {...el} setModalState={setModalState}></CourtCard>
            </Col>
          ))}
      </Row>
      <CourtModal
        modalState={modalState}
        setModalState={setModalState}
        setCourts={setCourts}
      ></CourtModal>
    </div>
  );
};

export default Courts;
