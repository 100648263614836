import React from "react";
import classes from "./TermsContent.module.scss";

const PrivacyContent = () => {
  return (
    <div className={classes.content}>
      <p className={classes.title}>Politica de confidențialitate</p>
      <p className={classes.updateText}>
        Ultima actualizare a avut loc în data de 5 August 2020
      </p>

      <p className={classes.text}>
        Vă rugăm să vizitați această pagină periodic pentru a lua la cunoștiință
        eventuale schimbări la politica de confidențialitate. Folosirea
        platformei itenisiasi.ro și rezervarea telefonică sunt condiționate de acceptarea acestui document și
        a Termenilor și Condițiilor de utilizare.
      </p>

      <p className={classes.heading}>Pe scurt</p>
      <p className={classes.text}>
        Nu folosim datele dumneavoastră în niciun alt fel decât pentru a
        putea onora rezervarea dumneavoastră.
      </p>

      <p className={classes.heading}>Date cu caracter personal</p>
      <p className={classes.text}>
        Colectăm numele și email-ul furnizat de către dumneavoastra prin logarea
        cu un cont de Google sau Facebook.
      </p>
      <p className={classes.text}>
        Folosim date personale strict pentru a onora o obligație contractuală
        față de dumneavoastră (rezervarea unui teren sportiv).
      </p>
      
      <p className={classes.text}>
        Aceste date sunt păstrate criptat într-o bază de date pe o durată de 12
        luni după data rezervării și sunt folosite pentru a vă identifica
        în cazul în care avem nevoie să soluționam eventuale probleme legate de
        rezervare (exemple: schimbarea rezervării sau restituirea plății în
        caz de condiții care nu au permis utilizarea terenului pe timpul
        rezervării).
      </p>
      <p className={classes.text}>
        Doar noi (CLUB iTENIS IASI, angajații și personalul contractat pentru
        mentenanța platformei itenisiasi.ro) avem acces la aceste date și nu le
        vom partaja niciodata partenerilor comerciali. Comunicarea între noi și
        dumneavoastra (email sau telefonic) va fi strict legată de rezervările
        dumneavoastră (email de confirmare, email sau telefon pentru a vă anunța
        de o problemă care necesită mutarea/anularea rezervării). Nu vă vom
        contacta niciodata în scop publicitar decât dacă dumneavoastră cereți
        expres acest lucru.
      </p>
      <p className={classes.text}>
        Ne puteți contacta la contact@itenisiasi.ro sau la 0771 257 899 pentru a
        cere informații legate de datele cu caracter personal pe care le avem
        despre dumneavoastra sau pentru a ne cere să ștergem permanent aceste
        date.
      </p>
      <p className={classes.text}>
        Întrucât CLUB iTENIS IAȘI permite folosirea platformei de rezervări
        online doar persoanelor cu vârsta peste 18 ani, noi nu colectăm în
        cunoștință de cauză date cu caracter personal de la persoane minore.
        Dacă copilul dvs. a furnizat sau credeți că a furnizat acest tip de
        informații pe site-ul nostru web, vă încurajăm să ne contactați imediat
        și vom face tot posibilul pentru a elimina prompt aceste date din
        înregistrările noastre.
      </p>

      <p className={classes.heading}>Plata online</p>
      <p className={classes.text}>
        Plata online este realizată prin serviciul Stripe care primește direct
        datele cardului dumneavoastră. Noi (CLUB iTENIS IAȘI) nu avem sub nicio
        formă acces la aceste date și prin urmare nu le stocăm.
      </p>
      <p className={classes.text}>
        Stripe este un operator de plăți reputabil folosit la nivel
        internațional de companii mari. Acesta folosește cele mai bune
        instrumente și practici de securitate pentru a menține un nivel ridicat
        de securitate. Pentru detalii tehnice puteți accesa
        https://stripe.com/docs/security/stripe
      </p>

      <p className={classes.heading}>Cookies</p>
      <p className={classes.text}>
        itenisiasi.ro foloseste cookie-uri externe necesare autentificării cu
        Google sau Facebook și pentru posibilitatea plății online cu Stripe. Nu
        folosim cookie-uri de marketing sau analitice sub nicio formă. Le puteți
        șterge din setările browser-ului dumneavoastră oricând fără a afecta
        experiența dumneavoastră pe site-ul nostru.
      </p>
    </div>
  );
};

export default PrivacyContent;
