import axios from "axios";
import { getAccessToken, adminAccess, hasTokenExpired } from "./auth";
import { navigate } from "@reach/router";
import { base_url } from "./globals";

const instance = axios.create({
  baseURL: base_url
});

instance.interceptors.request.use(
  config => {
    if (adminAccess() && hasTokenExpired()) {
      navigate("/admin/login");
    }

    const accessToken = getAccessToken();
    const customHeader = { "X-ACCESS-TOKEN": accessToken };
    config.headers = { ...config.headers, ...customHeader };
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;
