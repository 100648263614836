import React from "react";
import AdminHeader from "../AdminHeader/AdminHeader";
import { Router } from "@reach/router";
import { AdminRoute } from "../../Common/Routing/Routing";
import NewBookings from "../NewBookings/NewBookings";
import BookingsList from "../BookingsList/BookingsList";
import Courts from "../Courts/Courts";

const AdminLayout = () => {
  return (
    <div>
      <AdminHeader></AdminHeader>
      <Router>
        <AdminRoute component={NewBookings} path="/add-bookings" />
        <AdminRoute component={BookingsList} path="/bookings" />
        <AdminRoute component={Courts} path="/courts" />
      </Router>
    </div>
  );
};

export default AdminLayout;
